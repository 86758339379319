import { MediaService } from '../../services/media.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { DynamicDataModel } from '../../../core/models/dynamicdata.model';

@Injectable({ providedIn: 'root' })

export class DynamicDataResolver implements Resolve<DynamicDataModel> {
  constructor(private mediaservice: MediaService) {};
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,

  ):Observable<any> {
    return this.mediaservice.getDynamicData(state)
  }
}