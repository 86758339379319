<div cdkTrapFocus class="auth-modal__content" [ngClass]="{ 'auth-content': !changedEmail }">
  <button class="close-btn" (click)="bsModalRef.hide()">
    <img src="assets/icons/close.svg" alt="" />
  </button>
  <div *ngIf="!changedEmail">
    <h2 style="margin-bottom: 2rem">Change Email</h2>
    <form class="auth-form" [formGroup]="form">
      <hch-input
        field="email"
        formControlName="currentEmail"
        placeholder="Current Email"
        type="email"
        [readonly]="true"
        [errorRequired]="currentEmail?.errors?.['required']"
      ></hch-input>
      <hch-input
        field="email"
        formControlName="newEmail"
        placeholder="New Email"
        type="email"
        [dirty]="newEmail?.dirty"
        [errorInvalid]="newEmail?.errors?.['invalidEmail']"
        [errorRequired]="newEmail?.errors?.['required']"
        [errorMessage]="errorMessage"
      ></hch-input>
      <button class="hch-btn hch-btn-primary form-btn" (click)="confirm()" [disabled]="form.invalid || submitting">
        {{ submitting ? 'Updating . . .' : 'Confirm' }}
      </button>
    </form>
  </div>
  <div class="step change-success" *ngIf="changedEmail">
    <img [src]="mediaService.DYNAMIC_LOGO | async" class="logo" alt="Home Captain logo" />
    <h2>Email Changed!</h2>
    <img src="assets/images/auth/password-changed.png" class="done" alt="Email changed logo" />
    <!-- <p class="auth-desc text-s">You will receive an email shortly confirming your email change.</p> -->
    <button class="hch-btn hch-btn-secondary done-btn" (click)="bsModalRef.hide()">Close</button>
  </div>
</div>
