import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { fromEvent, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map } from 'rxjs/operators';

import { states } from 'src/app/core/data/state-options';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { toCapitalCase } from 'src/app/core/helpers';

import { ActivityService } from 'src/app/core/services/activity.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DataTreeApiService } from 'src/app/core/services/data-tree-api.service';
import { SearchService } from 'src/app/core/services/search.service';

export const abbreviations = {
  ROAD: 'RD',
  STREET: 'ST',
  AVENUE: 'AVE',
  BOULEVARD: 'BLVD',
  DRIVE: 'DR',
  COURT: 'CT',
  POINT: 'PT',
  PLACE: 'PL',
  SQUARE: 'SQ',
};

interface SearchResult {
  streetAddress?: string;
  fullAddress?: string;
  city?: string;
  county?: string;
  state?: string;
  zip?: string;
  zipCode4?: string;
  type?: string;
  subdomain?: string;
}

@Component({
  selector: 'hch-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent extends Unsubscriber implements OnInit, AfterViewInit {
  @Input() btnType = 'btn-primary';
  @Input() placeholder = '';
  @Input() type: 'location' | 'address' = 'location';
  @Input() size: 'mobile' | 'desktop' = 'desktop';
  @Input() nearby: boolean = true;
  @Output() searchEvent = new EventEmitter<Params>();
  @Output() emitFocus = new EventEmitter<boolean>(false);

  data: SearchResult[] = [];
  focused = false;
  matched = false;
  isActiveSearch = false;
  searchTerm = '';
  disableSearch = false;
  isSearching = false;

  errorMessage = '';

  focusedSearchNearBy = false;
  focusedResult = 0;

  selectedItem: SearchResult = {
    city: '',
    state: '',
    subdomain: '',
  };
  cursorPos = 0;

  curLat: string | number = 0;
  curLong: string | number = 0;
  navAllowed = false;

  @ViewChild('searchInput') searchInput?: ElementRef;
  @ViewChild('searchContainer') searchContainer?: ElementRef;
  @ViewChildren('searchResults') searchResults?: QueryList<ElementRef>;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: Event): void {
    if (!this.searchContainer?.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.isActiveSearch = false;
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapePress(event: Event): void {
    const results = this.searchResults?.toArray().map((el) => el.nativeElement);
    if ((results && results.includes(event.target)) || this.searchInput?.nativeElement.contains(event.target)) {
      this.isActiveSearch = false;
    }
  }

  constructor(
    private activityService: ActivityService,
    private authService: AuthService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private dataTreeApiService: DataTreeApiService
  ) {
    super();
  }

  getMyLocationInfo() {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.curLong = position.coords.longitude;
            this.curLat = position.coords.latitude;
            this.navAllowed = true;
          },
          (err) => {
            this.navAllowed = false;
          }
        );
      } else {
      }
    } catch (err) {}
  }

  searchNearBy() {
    this.searchTerm = '';
    this.data = [];
    this.sendDataToParent({
      position: this.curLat + ',' + this.curLong,
      distance: '1',
      // distance: '1mi',
    });
  }

  ngOnInit() {
    if (this.type === 'location') {
      this.addSubscription = this.route.queryParams.subscribe((params) => {
        let city = params['city'];
        if (city) {
          this.searchTerm = city;
        } else if (this.searchTerm) {
          this.onSearch();
        }
        this.searchLocations();
      });
    }
  }

  ngAfterViewInit() {
    this.getMyLocationInfo();
    this.addSubscription = fromEvent(this.searchInput?.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => event.target.value),

        // if character length greater then 3
        filter((res) => {
          this.errorMessage = '';
          const search = res.length > 2;
          if (!search) this.data = [];
          return search;
        }),

        // Time in milliseconds between key events
        debounceTime(300),

        // If previous query is different from current
        distinctUntilChanged()
      )
      // subscription for response
      .subscribe((text: string) => {
        this.isActiveSearch = true;
        if (this.type === 'address') {
          this.searchAddresses();
        } else if (this.type === 'location') {
          this.searchLocations();
        }
      });
  }

  get provider() {
    return this.searchService.provider;
  }

  formatAddress(address: any) {
    let { City, State, Zip } = address;
    let fullAddress: string = '';

    City = toCapitalCase(City);

    if (City) {
      fullAddress += City;
      if (State) {
        fullAddress += ', ' + State;
        if (Zip) {
          fullAddress += ' ' + Zip;
        }
      }
    } else if (State) {
      fullAddress += State;
      if (Zip) {
        fullAddress += Zip;
      }
    } else if (Zip) {
      fullAddress += Zip;
    }
    return fullAddress;
  }

  formatForAbbreviations(searchString: string) {
    for (let key in abbreviations) {
      let re = new RegExp(key, 'gi');
      // @ts-ignore
      searchString = searchString.replace(re, abbreviations[key]);
    }
    return searchString;
  }

  /**
   * Searches home addresses on the Home Value page.
   * @returns
   */
  searchAddresses() {
    if (this.searchTerm.length < 3) {
      this.data = [];
      return;
    }
    this.isSearching = true;
    this.data = [];
    this.cursorPos = 0;
    const updatedSearch = this.formatForAbbreviations(this.searchTerm);

    this.addSubscription = this.dataTreeApiService
      .getReport({
        ProductNames: ['DataTreeAVMLite'],
        SearchType: 'FullAddress',
        FullAddress: updatedSearch,
        ReferenceId: 'ABC',
      })
      .pipe(
        map((res: any) => {
          if (res.LitePropertyList) {
            let data: SearchResult[] = [];
            res.LitePropertyList.forEach((item: any) => {
              const info = item;
              let zip = info.Zip;
              if (typeof zip == 'number' && zip < 10000) zip = '0' + String(zip);
              let fullAddress = this.formatAddress({
                City: info.City,
                State: info.State,
                Zip: zip,
              });
              data.push({
                streetAddress: toCapitalCase(info.Address),
                fullAddress,
                city: info.City,
                county: info.County,
                state: info.State,
                zip: info.Zip,
                zipCode4: info.ZipCode4,
                type: 'address',
              });
            });
            this.data = data;
          } else if (res.Reports) {
            let data: SearchResult[] = [];
            res.Reports.forEach((item: any) => {
              const info = item.Data.SubjectProperty.SitusAddress;
              let zip = info.Zip9.substr(0, 5);
              if (typeof zip == 'number' && zip < 10000) zip = '0' + String(zip);
              let fullAddress = this.formatAddress({
                City: info.City,
                State: info.State,
                Zip: zip,
              });
              data.push({
                streetAddress: toCapitalCase(info.StreetAddress),
                fullAddress,
                city: info.City,
                county: info.County,
                state: info.State,
                zip: info.Zip9.substr(0, 5),
                zipCode4: info.Zip9.substr(6, 9),
                type: 'address',
              });
            });
            this.data = data;
          } else {
            this.data = [];
          }
        }),
        catchError((error) => {
          if (error.msg) this.errorMessage = error.msg;
          return throwError(() => error);
        }),
        finalize(() => {
          this.disableSearch = false;
          this.isSearching = false;
        })
      )
      .subscribe();
  }

  /**
   * Searches locations (city, state) on the Buy page.
   * @returns
   */
  searchLocations() {
    if (this.searchTerm.length < 1) {
      this.data = [];
      return;
    }
    this.isSearching = true;
    this.data = [];
    this.cursorPos = 0;

    this.addSubscription = this.searchService
      .searchLocations(this.searchTerm)
      .pipe(
        map((res: any) => {
          this.data = res.map((item: any) => {
            let zip = item.zip;
            if (typeof zip == 'number' && zip < 10000) zip = '0' + String(zip);
            let fullAddress = this.formatAddress({
              City: item.city,
              State: item.state.toUpperCase(),
              Zip: zip,
            });
            let streetAddress = toCapitalCase(item.address || item.name);
            return {
              city: item.city,
              state: item.state,
              zip: item.zip,
              fullAddress,
              streetAddress,
              type: item.type,
              subdomain: item.broker_domain,
            };
          });

          // Unshift states to top of list
          let filteredStates = [...states];
          filteredStates.shift(); // remove first element: "Please select your state"
          let filteredStates2 = [...filteredStates];
          filteredStates = filteredStates.filter((state) => {
            if (state.text.toLowerCase().includes(this.searchTerm.toLowerCase()) && state.value) return true;
            return false;
          });
          let displayedStates = filteredStates.map((state) => ({
            fullAddress: state.text,
            type: 'State',
            state: state.postal,
          }));

          let cities = [...this.data];
          let filteredCities: SearchResult[] = [];

          // filter out cities in restricted states
          cities.forEach((city) => {
            filteredStates2.forEach((state) => {
              if (city.state === state.postal && state.value) {
                filteredCities.push(city);
              }
            });
          });
          this.data = filteredCities;

          const results =
            this.data.length + displayedStates.length >= 10
              ? this.data.slice(0, 10 - displayedStates.length)
              : this.data;

          this.data = [...displayedStates, ...results];
        }),
        catchError((error) => {
          if (error.msg) this.errorMessage = error.msg;
          return throwError(() => error);
        }),
        finalize(() => {
          this.disableSearch = false;
          this.isSearching = false;
        })
      )
      .subscribe();
  }

  changeItem(item: SearchResult) {
    this.selectedItem = item;
  }

  selectItem(item: SearchResult) {
    console.log({item})
    this.selectedItem = item;
    this.focused = false;
    this.isActiveSearch = false;
    this.onSearch();
  }

  onSearch() {
    if (!this.selectedItem.city) {
      if (this.data.length > 0) {
        this.selectedItem = this.data[0];
      } else {
        this.selectedItem.city = '';
        this.selectedItem.state = '';
      }
    }
    if (this.type === 'location') {
      let data = {
        city: toCapitalCase(this.selectedItem.city || ''),
        state: this.selectedItem.state,
        subdomain: this.selectedItem.subdomain,
      };
      this.sendDataToParent(data);
      const user = this.authService.currentUser.getValue();
      if (user && !user.firstSearch) {
        this.addSubscription = this.activityService.logFirstSearch(data).subscribe();
      }
    } else if (this.type === 'address') {
      let data = {
        Address: this.selectedItem.streetAddress,
        City: this.selectedItem.city,
        County: this.selectedItem.county,
        Zip: this.selectedItem.zip,
        ZipCode4: this.selectedItem.zipCode4,
        State: this.selectedItem.state,
      };
      this.sendDataToParent(data);
    }
  }

  focusItem(index?: string | number) {
    setTimeout(() => {
      document.getElementById(`search-input-item-${index}`)?.focus();
    }, 0);
  }

  focusPrevItem(event: Event) {
    const tabNotPressed = (event as KeyboardEvent).key !== 'Tab';
    if (tabNotPressed) event.preventDefault();
    if (this.cursorPos > 0) {
      this.cursorPos--;
      this.changeItem(this.data[this.cursorPos]);
      if (tabNotPressed) this.focusItem(this.cursorPos);
    } else {
      if (tabNotPressed) this.focusItem('nearby');
      // this.searchTerm = '';
    }
  }

  focusNextItem(event: Event) {
    const tabNotPressed = (event as KeyboardEvent).key !== 'Tab';
    if (tabNotPressed) event.preventDefault();
    if (document.activeElement === document.getElementById('search-input-item-nearby')) {
      this.changeItem(this.data[this.cursorPos]);
      if (tabNotPressed) this.focusItem(this.cursorPos);
    } else if (this.cursorPos < this.data.length - 1) {
      this.cursorPos++;
      this.changeItem(this.data[this.cursorPos]);
      if (tabNotPressed) this.focusItem(this.cursorPos);
    }
  }

  sendDataToParent(value: Params) {
    console.log({value})
    this.searchEvent.emit({
      country: null,
      city: null,
      state: null,
      position: null,
      distance: null,
      ...value,
      page: 1,
    } as Params);
  }

  clearSearch() {
    this.searchTerm = '';
    this.data = [];
    (this.searchInput?.nativeElement as HTMLInputElement).focus();
  }
}
