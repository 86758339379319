<div class="card calculator" id="listing-calculator">
  <h4 class="sub-title">Payment Calculator</h4>
  <div class="calculator-body">
    <div class="price">
      <img *ngIf="type != 'full'" src="assets/icons/pie-chart-big.svg" alt="pie-chart" />
      <h5>{{ monthlyPayment | tonumber : 'ceil' | currency : 'USD' : 'symbol' : '1.0-0' }}/Mo</h5>
      <span>est. payment</span>
    </div>
    <form class="calc-container" [formGroup]="form">
      <div class="calc-group">
        <div class="calc-item form-group">
          <label class="calc-type" for="price">Down Payment</label>
          <div style="display: flex">
            <input
              class="price1 first"
              id="price"
              mask="separator.0"
              prefix="$"
              thousandSeparator=","
              [dropSpecialCharacters]="true"
              formControlName="downPaymentValue"
            />
            <input
              class="percent1 last"
              mask="separator.2"
              suffix="%"
              [dropSpecialCharacters]="true"
              formControlName="downPaymentPercent"
            />
          </div>
        </div>
        <div class="calc-item form-group">
          <label class="calc-type" for="interest">Interest Rate</label>
          <input
            class="percent2"
            id="interest"
            mask="separator.2"
            suffix="%"
            [dropSpecialCharacters]="true"
            formControlName="interestRate"
          />
        </div>
      </div>
      <div class="calc-group">
        <div class="calc-item loan" style="min-width: 150px">
          <label class="calc-type">Loan Type</label>
          <mat-select formControlName="loanPeriod" [value]="loanTypes[0]" (click)="$event.preventDefault()">
            <mat-option *ngFor="let t of loanTypes" [value]="t.value" (click)="$event.preventDefault()">
              {{ t.label }}
            </mat-option>
          </mat-select>
        </div>
        <div class="calc-item form-group">
          <label class="calc-type" for="home-price">Home Price</label>
          <input
            class="price2"
            id="home-price"
            mask="separator.0"
            prefix="$"
            thousandSeparator=","
            formControlName="homePrice"
            readonly
          />
        </div>
      </div>
    </form>
  </div>
</div>
