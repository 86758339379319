<div cdkTrapFocus class="auth-modal__content">
  <button autofocused class="close-btn focus-link" (click)="close()" aria-label="Close sign-in modal">
    <img src="assets/icons/close.svg" style="width: 1rem; height: 1rem" alt="Close sign-in modal" />
  </button>
  <img [src]="mediaService.DYNAMIC_LOGO | async" class="logo" [ngClass]="{ 'has-desc': description }" alt="Home Captain Logo" />
  <h5 class="auth-title" [ngClass]="{ 'has-desc': description }">{{ title }}</h5>
  <p *ngIf="description" class="auth-description text-m">{{ description }}</p>
  <form class="auth-form" [formGroup]="loginForm">
    <hch-input
      placeholder="Email"
      formControlName="email"
      field="email"
      [dirty]="email?.dirty"
      [errorInvalid]="email?.errors?.['invalidEmail']"
      [errorRequired]="email?.errors?.['required']"
      [errorMessage]="authErrMsg"
    ></hch-input>

    <!-- <div class="form-group" *ngIf="notConfirmed">
      <input
        placeholder="Enter verification code"
        formControlName="verifyCode"
        class="text-s"
        type="text"
        [ngClass]="{ 'error-field': (submitted || verifyCode.touched) && verifyCode.errors?.required }"
      />
      <label class="placeholder text-s">Verification code</label>
      <div class="error text-xs" *ngIf="(submitted || verifyCode.touched) && verifyCode.errors?.required">
        <img class="alert-img" src="assets/icons/alert.svg" alt="Code is required" />
        <span class="text-xs error-text">Code is required</span>
      </div>
      <div class="error text-xs" *ngIf="verifyErrMsg && !verifyCode.errors?.required">
        <img class="alert-img" src="assets/icons/alert.svg" alt="Verification error" />
        <span class="text-xs error-text">{{ verifyErrMsg }}</span>
      </div>
    </div> -->

    <!-- class="form-group password-field" -->
    <div class="password-field" *ngIf="!notConfirmed">
      <hch-input
        placeholder="Password"
        formControlName="password"
        field="password"
        [dirty]="password?.dirty"
        [type]="showPassword ? 'text' : 'password'"
        [errorRequired]="password?.errors?.['required']"
        [errorMessage]="pwdErrMsg"
      ></hch-input>
      <button class="visible-btn focus-link" (click)="togglePassword($event)">
        <img *ngIf="!showPassword" src="assets/icons/invisible.svg" alt="Hide password" />
        <img *ngIf="showPassword" src="assets/icons/visible.svg" alt="Show passowrd" />
      </button>
      <a
        class="hch-btn-link forgot-password focus-link"
        style="text-decoration: underline"
        (click)="forgotPassword()"
        href="javascript:void(0);"
      >
        Forgot password?
      </a>
    </div>

    <!-- <div class="mb-4">
      <re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled">
      </re-captcha>
    </div> -->
    <button
      *ngIf="!notConfirmed"
      class="hch-btn hch-btn-primary form-btn"
      style="margin-top: 1rem"
      type="submit"
      (click)="login()"
      [disabled]="loginForm.invalid || submitted"
    >
      {{ submitted ? 'Signing in . . .' : 'Sign In' }}
    </button>
    <!-- <button
      *ngIf="notConfirmed"
      class="hch-btn hch-btn-primary form-btn"
      (click)="verify()"
      [disabled]="loginForm.invalid || submitted"
    >
      {{ submitted ? 'Verifying . . .' : 'Verify' }}
    </button>

    <div class="d-flex align-items-center mt-4" *ngIf="notConfirmed">
      <span class="text-s">Didn't you receive the code?</span>
      <div class="btn btn-link resend-btn ml-2" (click)="resendCode()">
        {{ resendingCode }}
      </div>
    </div> -->
  </form>
  <div class="not-member">
    <span class="text-s">Not a member?</span>
    <a
      class="hch-btn-link focus-link"
      style="text-decoration: underline"
      href="javascript:void(0);"
      (click)="createAccount()"
    >
      Create a free account
    </a>
  </div>
  <div class="or">
    <hr />
    <span class="text-s">or</span>
    <hr />
  </div>
  <div class="social-btns">
    <a class="hch-social-btn hch-google-btn focus-link" (click)="googleLogin()" aria-label="Continue with Gmail">
      <img src="assets/icons/google.svg" alt="Continue with Gmail" aria-label="Continue with Gmail" />
      Continue with Gmail
    </a>
    <!-- <a class="hch-social-btn hch-facebook-btn focus-link" (click)="facebookLogin()" aria-label="Continue with Facebook">
      <img src="assets/icons/facebook.svg" alt="Continue with Facebook" aria-label="Continue with Facebook" />
      Continue with Facebook
    </a> -->
  </div>
  <div>
    <span>View our</span>
    {{ ' ' }}
    <a
      id="pp-sign-in-btn"
      class="pp-modal focus-link"
      style="text-decoration: underline"
      href="javascript:void(0);"
      (click)="openPrivacyPolicyModal()"
    >
      Privacy Policy
    </a>
  </div>
</div>
