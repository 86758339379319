import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'object',
  standalone: true,
})
export class ObjectPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value) return [];
    for (let arg of args) {
      if (arg in Object && Object[arg as keyof Object]) {
        return Object[arg as keyof Object](value);
      }
      if (arg == 'keys') return Object.keys(value);
      if (arg == 'values') return Object.values(value);
      if (arg == 'entries') return Object.entries(value);
    }
    return [];
  }
}
