<div
  class="home-card"
  *ngIf="listing && !loading; else loadingTemplate"
  [ngClass]="{
    'saved-card': savedCard,
    'map-card': mapCard,
    'full-listing-card': fullListingCard
  }"
  (mouseenter)="fullListingService.selectListing(listing, provider!, fullListingCard)"
>
  <aside class="images">
    <div *ngIf="listing.photos && listing.photos.length > 0; else noImage">
      <button *ngIf="listing.photos.length > 0" class="view-gallery focus-link" (click)="viewGallery()">
        <img src="assets/icons/picture-double.svg" alt="View gallery" />
        View Gallery
      </button>
      <hch-favorite-icon
        [listing]="listing"
        [savedCard]="savedCard"
        [provider]="provider"
        display="ICON"
      ></hch-favorite-icon>
      <carousel [isAnimated]="true">
        <slide *ngFor="let slide of listing.photos.slice(0, 4)">
          <img [defaultImage]="defaultImg" [lazyLoad]="slide" class="gallery" />
        </slide>
      </carousel>
    </div>
    <ng-template #noImage>
      <div class="no-image">
        <img alt="" [src]="defaultImg" />
        <p>No Images Available</p>
      </div>
    </ng-template>
  </aside>

  <article class="contents">
    <span class="price">{{ formattedPrice }}</span>
    <section class="info">
      <span>{{ listing.bedrooms || 'N/A' }} bd</span>
      <span>{{ listing.bathrooms || 'N/A' }} ba</span>
      <span>
        {{
          listing.squareFeet
            ? listing.squareFeet + ' sqft'
            : listing.lotSizeArea
            ? listing.lotSizeArea + ' ' + listing.lotSizeUnits?.toLowerCase()
            : 'N/A'
        }}
      </span>
    </section>
    <div class="address">
      <div>{{ listing.address || 'N/A' }}</div>
      <div>
        {{ listing.city || 'N/A' }}, {{ listing.state || 'N/A' }}
        {{ listing.zipcode || 'N/A' }}
      </div>
    </div>
    <section class="data">
      <div>
        <span>Status: {{ ' ' }}</span>
        <span class="status">{{ (listing.standardStatus | titlecase) || 'N/A' }}</span>
      </div>
      <div class="time-ago" *ngIf="listing.modificationTimestamp">
        <div>
          <img src="assets/icons/stop-watch.svg" alt="new" />
          <span>Added to HomeCaptain</span>
        </div>
        <div>{{ listing.modificationTimestamp | timeago }} ago</div>
      </div>
      <div>
        <a [href]="listing.virtualTour" class="virtual-tour" *ngIf="listing.virtualTour">
          <img src="assets/icons/virtual.svg" alt="virtual" />
          Virtual Tour
        </a>
      </div>
    </section>
    <a
      class="see-full-listing"
      [routerLink]="routingService.getFullListingURL(listing.listingId!, provider!)"
      [queryParams]="routingService.staticQueryParams | async"
    >
      <!-- (click)="incrementViewCount($event)" -->
      See Full Listing
      <img src="assets/icons/chevron-right-blue.svg" alt="Right button" />
    </a>
    <section class="mls">
      <div *ngIf="listing.mlsLogo" class="mls-icon">
        <img [src]="getMLSLogo(listing.mlsLogo)" alt="MLS Icon" />
      </div>
      <div class="summary">
        <p>MLS #: {{ listing.mlsId || 'N/A' }}</p>
        <p>
          Listing provided by
          {{ listing.agentFullName || 'N/A' }}
          <ng-container *ngIf="listing.agentOfficeName">of {{ listing.agentOfficeName || 'N/A' }}</ng-container>
        </p>
        <p *ngIf="listing.agentPreferredPhone">
          Agent Phone:
          <a [href]="'tel:+1' + listing.agentPreferredPhone.replace('-', '')">
            {{ (listing.agentPreferredPhone | phone) || 'N/A' }}
          </a>
        </p>
        <p *ngIf="!listing.agentPreferredPhone && listing.agentOfficePhone">
          Office Phone:
          <a [href]="'tel:+1' + listing.agentOfficePhone.replace('-', '')">
            {{ (listing.agentOfficePhone | phone) || 'N/A' }}
          </a>
        </p>
      </div>
    </section>
  </article>
</div>

<ng-template #loadingTemplate>
  <div class="home-card">
    <div class="images">
      <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
    </div>
    <div class="contents">
      <div class="price">
        <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
      </div>
      <div class="categories">
        <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
      </div>
      <div class="address">
        <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
      </div>
      <div class="data">
        <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
      </div>
      <div class="see-full-listing">
        <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
      </div>
      <div class="mls">
        <ngx-skeleton-loader animation="progress"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</ng-template>
