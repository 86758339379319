import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  urlOrder = ['state','city','zip','neighborhood','bathroom','bedroom','price','property_type','lot_size','sqft','year_built','mls','bounds','zoom','sort','page'];

  private url: Map<any, any> = new Map();
  public urlMap$:BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() {}

  parseUrl(param: any) {

    let parse = param.substr(8).split(/\//);

    for(let i=0;i<parse.length;i++) {

      /* Check STATE */
      if(i == 0 && this.isUpperCase(parse[0]) && this.isLength(parse[0],2)) {
        this.url.set('state', parse[0]);
      }
      /* Check CITY & ZIP */
      if(i == 1 && this.isString(parse[1]) && this.isInteger(parse[2]) && this.isLength(parse[2], 5)) {
        this.url.set('city', parse[1]);
        this.url.set('zip', parse[2]);
      }
      /* Check CITY */
      else if(i == 1 && this.isString(parse[1]) && this.notContain(parse[1], '-')) {
        this.url.set('city', parse[1]);
      }

      if(parse[i].indexOf('-') !== -1) {

        //let key = parse[i].split('-');
        let key = parse[i].split(/-(.+)/);

        if(this.isContain(this.urlOrder, key[0])) {

          if(key[0] == 'sort') {
            let temp = key[1].split('_');
            this.url.set(key[0], [temp[0], temp[1]]);
          }
          else if(key[0] == 'price' || key[0] == 'year_built' || key[0] == 'lot_size' || key[0] == 'sqft') {
            let temp = key[1].split('_');
            temp[0] = temp[0] == 'min' ? '' : temp[0];
            temp[1] = temp[1] == 'max' ? '' : temp[1];
            this.url.set(key[0], [temp[0], temp[1]]);
          }
          else if(key[0] == 'property_type') {
            let temp = key[1].split(',');
            this.url.set(key[0], temp);
          }
          else if(key[0] == 'bounds') {
            let temp = key[1].split(',');
            this.url.set(key[0], temp);
          }
          else {
            this.url.set(key[0], key[1]);
          }
        }
      }
    }
    this.setMapValue();
  }

  createUrl() {

    let temp = [];

    // GET KEYS FROM URL Map //
    let keys = [...this.url.keys()];

    // ORDER KEYS BY ORDER ARRAY //
    let arr = this.urlOrder.filter(v => keys.includes(v));

    for(let i = 0; i<arr.length;i++) {
      let key = arr[i];

      switch (key) {
        case 'state':
        case 'city':
        case 'zip':
          var val = `${this.getUrlValue(key)}`;
              temp.push(val)
          break;
        case 'price':
        case 'lot_size':
        case 'sqft':
        case 'year_built':
          var minValue = this.getUrlValue(key)[0] == '' ? 'min' : this.getUrlValue(key)[0];
          var maxValue = this.getUrlValue(key)[1] == '' ? 'max' : this.getUrlValue(key)[1];
          var val = `${key}-${minValue}_${maxValue}`;
              temp.push(val);
          break;
        case 'property_type':
          var val = `${key}-${this.getUrlValue(key).join(',')}`;
              temp.push(val);
          break;
        case 'sort':
          var val = `${key}-${this.getUrlValue(key)[0]}_${this.getUrlValue(key)[1]}`;
              temp.push(val);
          break;
        case 'neighborhood':
            var val = `${key}-${this.getUrlValue(key).replace(/ /g, '_').replace(/\//g, '-')}`;
                temp.push(val);
            break;
        default:
          var val = `${key}-${this.getUrlValue(key)}`;
          temp.push(val)
          break;
      }
    }

    return `search/${temp.join('/')}`;

  }

  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////

  clearUrlValue() {
    this.url.clear();
  }

  getUrlValue(key: any) {
    return this.url.get(key);
  }

  setUrlValue(key: any,value: any) {
    this.url.set(key,value);
  }

  deleteUrlValue(key: any) {
    this.url.delete(key);
  }

  getMapValue() {
    return this.urlMap$.getValue();
  }

  setMapValue() {
    let newMap = new Map([...this.url]);
    this.urlMap$.next(newMap);
  }

  compareMaps(map1: any, map2: any) {
    //console.log('mapovi', map1, map2)
    var testVal;
    if (map1.size !== map2.size) {
        return false;
    }
    for (var [key, val] of map1) {
        testVal = map2.get(key);
        if(testVal instanceof Array) {
          //console.log(testVal, val)
          if(JSON.stringify(testVal) !== JSON.stringify(val)) return false;
        }
        else if (testVal !== val || (testVal === undefined && !map2.has(key))) {
            return false;
        }
    }
    return true;
  }

  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////

  isUpperCase(str: string | any) {
    return (str.toUpperCase() === str && (typeof str === 'string' || str instanceof String));
  }

  isInteger(num: any) {
    if (isNaN(num)) {
      return false;
    }
    let x = parseFloat(num);

    return (x | 0) === x;
  }

  isString(str: string | any) {
    return (typeof str === 'string' || str instanceof String);
  }

  isLength(num: any, len: number) {
    return (num.toString().length === len);
  }

  isContain(arr: any, needle: any) {
    return (arr.indexOf(needle) !== -1);
  }

  notContain(arr: any, needle: any) {
    return (arr.indexOf(needle) === -1);
  }

  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////

  generateDetailUrl(data: any) {

    let url = '';

    if (data['address'] == 'Address unavailable.') {
      url = `/real-estate/${data['address'].slice(0, -1).replace(/ /g, '-').replace(/\//g, '')}/${data['id']}`;
    }
    else if (data['address'] != undefined && data['address'] !== null) {
      let address = this.trimData(data['address']);
      url = `/real-estate/${data['state']}-${data['city'].replace(/ /g, '_')}-${address}/${data['id']}`;
    }
    else if(data['name'] != undefined && data['name'] !== null) {
      let name = this.trimData(data['name']);
      url = `/real-estate/${data['state']}-${data['city'].replace(/ /g, '_')}-${name}/${data['id']}`
    }
    else {
      url = `/real-estate/address-unavailable/${data['id']}`;
    }

    return url;

  }

  trimData(data: any) {
    return data.replace(/\//g, '').trim().replace(/ /g, '_').replace(/\_+/g, '_');
  }

  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////
  //////////////////////////////////////////////////////  

  isRedirect(data: any) {
    let url = new URL(window.location.origin);
    let sub = this.getSubdomain();
    console.log({sub})
    console.log({data})
    let isRedirect = false;

    if(environment['redirect']) {
      if(data['subdomain'] == null && sub == '' || sub == data['subdomain']) {
        isRedirect = false;
      }
      else if(sub != data['subdomain']) {
        isRedirect = true;
      }
    }

    return isRedirect;
  }

  getSubdomain() {

    let sub = '';
    if(environment['redirect']) {
      let url = new URL(window.location.origin);
      let host = url.host;

      if(host.includes('www')) {
        let cutwww = host.substr(host.indexOf('.') + 1);
        if(cutwww == environment['domain']) {
          sub = '';
        }
        else {
          let index = cutwww.indexOf(environment['domain']);
          sub = cutwww.slice(0,index - 1);
        }
      }
      else {
        if(host == environment['domain']) {
          sub = '';
        }
        else {
          let index = host.indexOf(environment['domain']);
          sub = host.slice(0,index - 1);
        }
      }
    }
    
    return sub;

  }

  externalUrl(path: any, data: any, type: any, useProtocol: any = true) {
    console.log({path})
    let url = new URL(window.location.origin);
    let protocol;

    if(useProtocol) {
      protocol = url.protocol + '//';
    }
    else {
      protocol = '';
    }

    let host = url.hostname;
    let redirectUrl = '';
    let www = '';
    if(host.includes('www')) {
      www = 'www.';
    }

    if(type == null) {
      redirectUrl = `${protocol}${www}${environment['domain']}${path}`;
    }
    else {
      redirectUrl = `${protocol}${www}${data['subdomain']}.${environment['domain']}${path}`;
    }
    return redirectUrl;

  }

}
