import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

import { MediaService } from 'src/app/core/services/media.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { FooterService } from 'src/app/core/services/footer.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { BuyService } from 'src/app/buy/buy.service';

@Component({
  selector: 'hch-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
})
export class PageFooterComponent extends Unsubscriber implements OnInit {
  @Input() showInfo = false;
  @Input() short = false;
  @Input() fullHeight = false;

  authorized: boolean = false;
  modalRef!: BsModalRef;

  displayMls = false;
  complianceText = '';
  iconUrl: any  = '';
  // paramsMap: any = {};
  footerIcon: any = '';
  constructor(
    public authService: AuthService,
    public mediaService: MediaService,
    public routingService: RoutingService, // private sessionService: SessionService
    // private cdr: ChangeDetectorRef,
    public footerService: FooterService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    // Renders the mls data if on full listing or home value page
    if (
      this.router.url.includes('buy') ||
      this.router.url.includes('full-listing') ||
      this.router.url.includes('homevalue/estimation')
    ) {
      this.displayMls = true;
      this.addSubscription = this.footerService.getMlsInfo().subscribe((complianceText: string) => {
        if (complianceText) {
          this.complianceText = complianceText.replace(/<br>/i, '<br /><br />');
          this.complianceText = this.complianceText.replace(/ALT='IDX'>/i, "ALT='IDX'><br /><br />");
          this.complianceText = this.complianceText.replace(/<A HREF=/, '<br /><A HREF=');
        } else {
          this.complianceText = '';
        }
      });
      this.addSubscription = this.footerService.getMlsIcon().subscribe((iconUrl: string) => {
        if (iconUrl) this.iconUrl = iconUrl;
        else this.iconUrl = '';

        if (this.complianceText.toLowerCase().includes('<img')) {
          this.iconUrl = '';
        }
      });
    }
  }

  openTermsConditionsModal() {
    this.modalRef = this.modalService.showTermsConditionsModal();
    this.addSubscription = this.modalRef.onHidden?.subscribe(() => {
      setTimeout(() => {
        document.getElementById('tos-modal-btn')?.focus();
      }, 0);
    });
  }

  openPrivacyPolicyModal() {
    this.modalRef = this.modalService.showPrivacyPolicyModal();
    this.addSubscription = this.modalRef.onHidden?.subscribe(() => {
      setTimeout(() => {
        document.getElementById('pp-modal-btn')?.focus();
      }, 0);
    });
  }

  login() {
    this.modalService.showLoginModal();
  }

  register() {
    this.modalService.showRegisterModal('Create Your Free Account');
  }

  get copyrightYear() {
    return new Date().getFullYear();
  }

  get dateNow() {
    return new Date().toLocaleDateString();
  }
}
