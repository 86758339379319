import { Component, OnInit } from '@angular/core';
import { MediaService } from 'src/app/core/services/media.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { RealStaqService } from 'src/app/core/services/real-staq.service';
import { environment } from 'src/environments/environment';
import { UrlService } from 'src/app/core/services/url.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'hch-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  _isRoot: any = null;

  _subdomain: any = null;
  _brokerlogo: any = null;
  _brokerName: any = null;
  _tagName: any = null;
  _tagLine: any = '';
  _isStatic: any = true;
  _isRedirect: any = environment['redirect'];
  logo = 'https://cdn.myvolly.io/images/blank_image.png';
  subscriptionBroker: Subscription | undefined;

  constructor(public mediaService: MediaService, public routingService: RoutingService, public realStaqService: RealStaqService, public urlService: UrlService) {}
  ngOnInit(): void {
    this.init();
    this.subscriptionBroker = this.mediaService._broker$.subscribe((response: any) => {
      if(response != null) {
        this.logo = response;
      }
    })
  }
  init() {
    this._isRoot = (this._subdomain == '' && this._isStatic) ? true : false;

    if(this._isRedirect) {
      this._subdomain = this.urlService.getSubdomain();
      if(this._subdomain != '') {
        this.getMls(this._subdomain);
      }
      else {
        this.mediaService.setBroker(this.logo);
      }
    }
  }
  getMls(subDomain: any) {
    this.realStaqService.getMls(subDomain).subscribe((response: any) => {
      this.mediaService.setBroker(response[0]['top_broker']['logo']);
    });
  }
  ngOnDestroy() {
    if(this.subscriptionBroker) {
      this.subscriptionBroker.unsubscribe();
    }
  }

}
