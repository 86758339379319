import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { Listing, Provider } from 'src/app/core/models/listing.model';

import { FooterService } from 'src/app/core/services/footer.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { StaticMapService } from 'src/app/core/services/static-map.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { FullListingService } from 'src/app/full-listing/full-listing.service';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

@Component({
  selector: 'hch-listing-card',
  templateUrl: './listing-card.component.html',
  styleUrls: ['./listing-card.component.scss'],
})
export class ListingCardComponent extends Unsubscriber implements OnInit {
  @Input() componentId = '';
  @Input() mapCard = false;
  @Input() savedCard = false;
  @Input() fullListingCard = false;

  @Input() provider: Provider | undefined = '';
  @Input() loading: boolean = false;

  @Input()
  set listing(listing: Listing | undefined) {
    if (listing && listing.price && !isNaN(Number(listing.price))) {
      this.formattedPrice = '$' + Number(listing.price).toLocaleString();
    } else {
      this.formattedPrice = 'N/A';
    }
    this._listing = listing;
  }
  get listing() {
    return this._listing;
  }
  private _listing: Listing | undefined;

  imageObject = [
    { thumbImage: 'assets/images/buy/pixelate.png' },
    { thumbImage: 'assets/images/buy/pixelate.png' },
    { thumbImage: 'assets/images/buy/pixelate.png' },
    { thumbImage: 'assets/images/buy/pixelate.png' },
  ];
  focusedGallery = 0;

  defaultImg = 'assets/images/buy/no-image.png';
  isScrolling = false;
  updating = false;
  formattedPrice: string = '';
  displayNoImage = false;
  googleImage = false;

  slideConfig = {
    arrows: true,
    dots: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    infinite: false,
  };

  carouselSubject = new Subject();

  constructor(
    private footerService: FooterService,
    public fullListingService: FullListingService,
    private modalService: ModalService,
    public routingService: RoutingService,
    public staticMapService: StaticMapService
  ) {
    super();
  }

  ngOnInit(): void {}

  get listingId() {
    let lId = 'listing-slide-' + this.listing?.mlsId;
    if (this.savedCard) lId += '-saved-card';
    if (this.mapCard) lId += '-map-card';
    if (this.fullListingCard) lId += '-full-card';
    return lId;
  }

  getMLSLogo(url: string) {
    return this.footerService.getIconPathFromUrl(url);
  }

  viewGallery() {
    if (this.listing && this.provider) {
      this.modalService.showGalleryModal(this.listing, this.provider);
    }
  }

  // incrementViewCount(event: any) {
  //   event.preventDefault();
  //   // this.sessionService.incrementViewCount();
  // }
}
