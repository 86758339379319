<a
  class="logo-link"
  aria-label="Go to the Home Captain home page"
  [routerLink]="routingService.homeMarketplaceUrl | async"
  [queryParams]="routingService.staticQueryParams | async"
>
  <img
    src="{{logo}}"
    defaultImage="https://cdn.myvolly.io/images/blank_image.png"
    alt="Home Captain logo"
    height="48px"
  />
</a>
