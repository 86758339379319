import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UrlParserService } from 'src/app/core/services/url-parser.service';
import { RoutingService } from './routing.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ActivityService } from 'src/app/core/services/activity.service';
import { DynamicDataModel } from '../models/dynamicdata.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
/**
 * Handles media for Citi branded site
 *
 * DO NOT IMPORT THIS FILE, ONLY IMPORT media.service.ts
 *
 * File replacements handle the injection of this service when building with the corresponding configuration
 */
@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private sanitizer: DomSanitizer, private urlparserservice: UrlParserService, private routingService: RoutingService, private http: HttpClient, private activityService: ActivityService, private router: Router, private route: ActivatedRoute) {
    // this.getDynamicData();

  }
  private currentYear = new Date().getFullYear();
  private customSiteId: string = this.routingService.nmls.value;
  // public data = {
  //   logo: 'https://cdn.uat.myvolly.io/images/jyb_logo_green_blue.png',
  //   footerLogo: 'https://cdn.uat.myvolly.io/images/jyb_logo_green_blue.png',
  //   site: 'Truhome',
  //   state_selector: false,
  //   logo_header: 'https://cdn.uat.myvolly.io/images/jyb_logo_green_blue.png',
  //   text_hero_h1: '',
  //   text_hero_body: `Citi has also teamed up with Home Captain to help you with your home search. With Home Captain, you can search properties or estimate how much your home is. Home Captain’s Concierge team can even match you with a real estate agent in your area. Together, Citi and Home Captain will work together as a team throughout the entire mortgage process, providing you with the personal touch you deserve and the resources you'll need to make your new home dreams come true.`,
  //   text_how_it_works_header: `Take the stress out of home buying and selling. Your Citi and Home Captain team are with you each step of the way!`,
  //   concierge_email_url:'mailto:citi.jordan@homecaptain.com',
  //   concierge_email: 'citi.jordan@homecaptain.com',
  //   footer_disclaimer_text: `Home Captain Services are provided and operated by Home Captain Inc. Real estate agent/broker services and Citi Mortgage lending services are independently provided, and this advertisement should not be considered an endorsement of each other’s services. * You are not required to use Citi Mortgage as your mortgage lender.`,
  //   team_contact_me_phone: '1-800-Mortgage',
  //   team_page_disclaimer: `By selecting “Submit” and providing us your telephone number and email address, you are providing express “written” consent for Home Captain, Inc. as well as its authorized third parties, and Citibank, N.A. and its authorized third parties to contact you (including through automated means, such as autodialing, text where SMS/MMS may apply, and pre-recorded messaging) at the number and/or email you’ve provided to offer and/or advertise its products and services, even if your telephone number is currently listed on any internal, state, or national Do Not Call list. You are not required to provide this consent as a condition of purchasing any property, goods, or services. You may contact Home Captain, Inc. at 1-887-847-4032 to discuss services offered. You may contact Citibank, N.A. to discuss mortgage and/or home equity products and services by calling 1-800-Mortgage."`,
  //   team_page_copyright: `Copyright ${this.currentYear}. Citibank, N.A. NMLS # 412915. Member FDIC and Equal House Lender. Citi, Citi and Arc Design and other marks used herein are service marks of Citigroup, Inc. or its affiliates, used and registered throughout the world.`,
  //   cma_enabled: false,
  //   our_team_page_enabled: true, 
  //   primary_color: '',
  //   secondary_color: '',
  //   home_value_enabled: true,
  //   affordability_enabled: true, 
  //   bankLogo: 'https://cdn.uat.myvolly.io/images/jyb_logo_green_blue.png'
  // }
  // public data: any = this.getDynamicData();
  public data = new Subject<DynamicDataModel>();

  getDynamicData(route: any): Observable<any> {
    // console.log({route})
// window.location to pull subdomain
    let subdomain: any = window.location.hostname.split('.')[1]
    // let siteId: any = getSiteIdBySubdomain(subdomain);
    // console.log({subdomain})
    if (!subdomain) subdomain = 'dev3';
    // console.log({siteId})
    let params = new HttpParams();
    params = params.set('subdomain', subdomain);    
    return this.http.get(environment.HUBAPI_BASE_URL + '/dynamic/get-dynamic-data', {params})
      .pipe(
        map((data:any) => {
          if(data) {
            // this.data.next(data)
            // console.log({data})
            this.SITE.next(data.site);
            this.DYNAMIC_LOGO.next(data.logo);
            // this.HEADER_LOGO .next(data.logo);
            this.BANK_LOGO.next(data.logo);
            this.STATE_SELECTOR.next(data.state_selector == 'true' ? true : false);
            // this.LOGO_HEADER.next(data.logo);
            this.TEXT_HERO_H1.next(data.text_hero_h1);
            this.TEXT_HERO_BODY.next(data.text_hero_body);
            this.CONCIERGE_EMAIL_URL.next(data.concierge_email_url);
            this.CONCIERGE_EMAIL.next(data.concierge_email);
            this.TEAM_PAGE_DISCLAIMER.next(data.team_page_disclaimer);
            this.TEAM_PAGE_COPYRIGHT.next(data.team_page_disclaimer);
            this.HOME_VALUE_ENABLED.next(data.home_value_enabled == 'true' ? true : false);
            this.OUR_TEAM_PAGE_ENABLED.next(data.our_team_page_enabled == 'true' ? true : false);
            this.AFFORDABILITY_ENABLED.next(data.affordability_enabled == 'true' ? true : false);
            this.TEXT_HOW_IT_WORKS_HEADER.next(data.text_how_it_works_header);
            this.FOOTER_DISCLAIMER_TEXT.next(data.footer_disclaimer_text);
            this.CORPORATE_HOME_PAGE_ENABLED.next(data.corporate_home_page_enabled == 'true' ? true : false);
            if (this.CORPORATE_HOME_PAGE_ENABLED.getValue()) {
              this.routingService.homeUrl.next('/home'); 
              if (route.url === '/') {
                this.router.navigate(['/home']);
                this.DYNAMIC_LOGO.next(data.logo);  
                console.log(this.router.url);
              } 
            } else {
              this.routingService.homeUrl.next('/'); 
              this.DYNAMIC_LOGO.next(data.logo);
            }
            return data;
          }
        })
      )
    
  }

  // getSiteIdBySubdomain(domain) => {

  // }
  BANK_LOGO = new BehaviorSubject<string>('');
  
  public _broker$:BehaviorSubject<any> = new BehaviorSubject(null);
  setBroker(param: any) {
    this._broker$.next(param);
  }

  sanitize(data: any) {
    return this.sanitizer.sanitize(SecurityContext.HTML, data);
  }
  SITE = new BehaviorSubject<string>('');

  DYNAMIC_LOGO = new BehaviorSubject<string>('assets/images/volly-hc-color.png');
  HEADER_LOGO = new BehaviorSubject<string>('assets/images/volly-hc-color.png');

  STATE_SELECTOR = new BehaviorSubject<boolean>(false);
  CITI_FOOTER = new BehaviorSubject<boolean>(false);

  LOGO_HEADER = new BehaviorSubject<string>('');
  TEXT_HERO_H1 = new BehaviorSubject<string>('');
  TEXT_HERO_BODY = new BehaviorSubject<string>('');
  TEXT_HOW_IT_WORKS_HEADER = new BehaviorSubject<string>(`Take the stress out of home buying and selling. Your JourneyBank and Home Captain team are with you each step of the way!`);


  CONCIERGE_EMAIL_URL = new BehaviorSubject<string>('');
  CONCIERGE_EMAIL = new BehaviorSubject<string>('');

  FOOTER_DISCLAIMER_TEXT = new BehaviorSubject<string>(`Home Captain Services are provided and operated by Home Captain Inc. Real estate agent/broker services and Citi Mortgage lending services are independently provided, and this advertisement should not be considered an endorsement of each other’s services. * You are not required to use Citi Mortgage as your mortgage lender.`);

  TEAM_CONTACT_ME_PHONE = new BehaviorSubject<string>('1-800-Mortgage');

  TEAM_PAGE_DISCLAIMER = new BehaviorSubject<string>('');
  TEAM_PAGE_COPYRIGHT = new BehaviorSubject<string>('');
  // Display team page based off of data object
  // HOME_VALUE_URL = new BehaviorSubject<any>(this.data.home_value_url ? '/homevalue' : '');
  // TEAM_URL = new BehaviorSubject<any>(this.data.team_url ? '/team' : '');
  HOME_VALUE_ENABLED = new BehaviorSubject<boolean>(false);
  OUR_TEAM_PAGE_ENABLED = new BehaviorSubject<boolean>(false);
  AFFORDABILITY_ENABLED = new BehaviorSubject<boolean>(true);  
  CORPORATE_HOME_PAGE_ENABLED = new BehaviorSubject<boolean>(false);   
}


