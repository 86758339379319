import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

import { AuthService } from 'src/app/core/services/auth.service';
import { BuyService } from 'src/app/buy/buy.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { FavoritesService } from 'src/app/favorites/favorites.service';
import { MediaService } from 'src/app/core/services/media.service';
import { ActivityService } from 'src/app/core/services/activity.service';
import { catchError, finalize, map, throwError } from 'rxjs';

@Component({
  selector: 'hch-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent extends Unsubscriber implements OnInit {
  currentRoute: string = '';

  activatedRoute: any;

  constructor(
    public authService: AuthService,
    public favoritesService: FavoritesService,
    public routingService: RoutingService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    public mediaService: MediaService,
    public activityService: ActivityService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription = this.route.url.subscribe((urls) => {
      for (let url of urls) {
        if (url.path.includes('buy')) {
          this.currentRoute = 'buy';
          break;
        } else if (url.path.includes('homevalue')) {
          this.currentRoute = 'homevalue';
          break;
        } else if (url.path.includes('team')) {
          this.currentRoute = 'team';
          break;
        } else if (url.path.includes('favorite')) {
          this.currentRoute = 'favorite';
          break;
        } else if (url.path.includes('affordability')) {
          this.currentRoute = 'affordability';
          break;
        }
      }
    });
    // this.addSubscription = this.activityService.getDynamicData()
    //   .pipe(
    //     map((data:any) => {
    //       console.log({data})
    //     })
    //   )
    // .subscribe()
  }

  login() {
    this.modalService.showLoginModal();
  }

  register() {
    this.modalService.showRegisterModal('Create Your Free Account');
  }
}
