import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { setTheme } from 'ngx-bootstrap/utils';
import { CookieService } from 'ngx-cookie-service';
import { filter, tap } from 'rxjs';
import { AuthService } from './core/services/auth.service';
import { RoutingService } from './core/services/routing.service';
import { MediaService } from 'src/app/core/services/media.service';
@Component({
  selector: 'hch-root',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: [],
})
export class AppComponent implements OnInit {
  title = 'Home Captain';

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private routingService: RoutingService,
    public mediaService: MediaService,
    private router: Router
  ) {
    setTheme('bs4');

    this.authService.refresh().subscribe();

    // Sets shared query params for all routes
    this.route.queryParams.subscribe((params) => {
      this.routingService.setStaticQueryParams(params);
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart),
      tap((value: any) => {
        if(!value.url.includes('/buy') && !value.url.includes('/full-listing')) {
          this.mediaService.DYNAMIC_LOGO.next(this.mediaService.LOGO_HEADER.getValue());
        } 
      }))
      .subscribe();

    let cookie: any = this.cookieService.get('hch.state');
    if (cookie) {
      cookie = JSON.parse(cookie);
      this.routingService.buyQueryParams.next(cookie);
    }
  }

  // Sets resize for --vh variable, used for iOS displays
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }

  ngOnInit(): void {
    // this.authService.refresh().subscribe();
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }
}
